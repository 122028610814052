import React, { useRef, useState, useEffect } from "react"
import { useTransform, useMotionValue, motion } from "framer-motion"
import FloatLink from "./FloatLink"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import Img from "gatsby-image"
import Logo from "../Logo"

const Component = (props) => {
  const { linkData, image, location, logoYAdjustment } = props
  const itemRef = useRef(null)
  const constraintsRef = useRef(null)
  const { width, height } = useWindowDimensions()
  const stateX =
    typeof localStorage != "undefined" && localStorage.getItem("posX")
      ? parseFloat(localStorage.getItem("posX")) || 0
      : location?.state?.posX
      ? location.state.posX
      : 0
  const [start, setStart] = useState(-1 * Math.abs(stateX))
  const [end, setEnd] = useState(Math.abs(stateX))
  const [showBar, setShowBar] = useState(0)

  useEffect(() => {
    const posX = parseFloat(localStorage.getItem("posX") || 0) || 0
    x.set(posX)
    if (start !== 0 && end !== 0) {
      if (posX < 0 && posX < start) {
        x.set(start)
      }
      if (posX > 0 && posX > end) {
        x.set(end)
      }
    }
  }, [start, end])

  const x = useMotionValue(stateX || 0)
  const x2 = useTransform(x, (value) => value / 7)
  const x3 = useTransform(x, (value) => value / 4)
  const x4 = useTransform(x, (value) => value / 10)
  const xLogo = useTransform(x, (value) => value / 30)

  useEffect(() =>
    x.onChange((latest) => {
      localStorage.setItem("posX", latest)
    })
  )

  useEffect(() => {
    const center = Math.abs(stateX)
    setStart(-1 * center)
    setEnd(center)
    setTimeout(() => {
      if (itemRef && itemRef.current && itemRef.current.offsetLeft) {
        setStart(-1 * Math.abs(itemRef.current.offsetLeft))
        setEnd(Math.abs(itemRef.current.offsetLeft))
      }
      setShowBar(1)
    }, 400)
  }, [itemRef, width, height, stateX])

  function onMouseDown(e) {
    if (e.target && e.target.nodeName === "IMG") {
      e.preventDefault()
    } else if (e.srcElement && e.srcElement.nodeName === "IMG") {
      e.returnValue = false
    }
  }

  const progress = useTransform(x, [start, end], ["70%", "0%"])

  const transition = {
    dragElastic: 1,
    power: 1,
    ease: [0.17, 0.67, 0.83, 0.67],
  }

  const onScroll = (e) => {
    const constraint = 600
    const maxConstraint = 0.05
    const currentX = x.get()
    const positionFromStart = -1 * (currentX + start)
    const positionFromEnd = currentX + end
    let delta = e.deltaY

    if (positionFromStart <= constraint) {
      delta = delta * Math.max(maxConstraint, positionFromStart / constraint)
    }

    if (positionFromEnd <= constraint) {
      delta = delta * Math.max(maxConstraint, positionFromEnd / constraint)
    }

    const position = currentX + delta
    if (position >= start && position <= end) {
      x.set(position)
    } else {
      if (position < start && currentX > start) {
        x.set(start)
      }
      if (position > end && currentX < end) {
        x.set(end)
      }
    }
  }

  const levelZeroTopVH = logoYAdjustment ? parseFloat(logoYAdjustment) : 0;

  return (
    <div className={props.className} onWheel={onScroll}>
      <motion.div className="slideContainer" ref={constraintsRef}>
        <motion.div
          className="item"
          drag="x"
          ref={itemRef}
          dragConstraints={constraintsRef}
          dragTransition={transition}
          style={{ x }}
          onMouseDown={onMouseDown}
        >
          <Img
            alt="Smart Move"
            fluid={image}
            objectFit="cover"
            objectPosition="50% 50%"
            imgStyle={{
              objectFit: "cover",
              objectPosition: "50% 50%",
            }}
            fadeIn={false}
            loading="eager"
            style={{ height: "100vh" }}
          />
          +
          <div className="content">
            <motion.div className="onscreentext logo" style={{ x: xLogo }}>
              <Logo light={true}></Logo>
            </motion.div>
            <style>{`:root {--level-zero-y-adjustment:` + levelZeroTopVH + `;}`}</style>

            {linkData && (
              <>
                <motion.div
                  className="onscreentext center-link"
                  style={{ x: x4 }}
                >
                  {linkData
                    .filter((item) => !item.menuLevel)
                    .map((item, i) => (
                      <FloatLink
                        key={item.menuLevel + i}
                        title={item.link?.title}
                        target={item.link?.target}
                        to={item.link?.url}
                        left={item.posLeft?.replace(",", ".")}
                        style={"--yAdjustment:" + levelZeroTopVH + ";"}
                      />
                    ))}
                </motion.div>

                <motion.div className="onscreentext" style={{ x: x3 }}>
                  {linkData
                    .filter((item) => item.menuLevel === 1)
                    .map((item, i) => (
                      <FloatLink
                        key={item.menuLevel + i}
                        title={item.link?.title}
                        target={item.link?.target}
                        to={item.link?.url}
                        top={item.posTop?.replace(",", ".")}
                        left={item.posLeft?.replace(",", ".")}
                      />
                    ))}
                </motion.div>

                <motion.div className="onscreentext" style={{ x: x2 }}>
                  {linkData
                    .filter((item) => item.menuLevel === 2)
                    .map((item, i) => (
                      <FloatLink
                        key={item.menuLevel + i}
                        title={item.link?.title}
                        target={item.link?.target}
                        to={item.link?.url}
                        top={item.posTop?.replace(",", ".")}
                        left={item.posLeft?.replace(",", ".")}
                      />
                    ))}
                </motion.div>
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
      <div style={{ opacity: showBar }} className="progressBar">
        <motion.div className="progressItem" style={{ left: progress }} />
      </div>
    </div>
  )
}

export default Component

Component.defaultProps = {
  className: "slideWrap",
}
