import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import MenuLink from "./MenuLink"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import useSiteMetaQuery from "../../hooks/useSiteMetaQuery"
import useSiteSeoQuery from "../../hooks/useSiteSeoQuery"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"

const Layout = (props) => {
  const { children, linkData, title, location, description, image } = props
  const { height } = useWindowDimensions()
  const [realHeight, setRealHeight] = useState(height)

  const siteMeta = useSiteMetaQuery()
  const siteSeo = useSiteSeoQuery()

  useEffect(() => {
    if (height > 0) {
      setRealHeight(height)
    }
  }, [height])

  return (
    <>
      <SEOContext.Provider value={{ global: siteSeo }}>
        <Helmet
          htmlAttributes={{
            lang: location.pathname.includes("international") ? "en" : "sv",
          }}
        >
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <title>{title ?? siteMeta.title}</title>
          <body className="frontpage" />
          {realHeight && realHeight > 0 && (
            <style type="text/css">
              {`
        :root {
          --innerHeight: ${realHeight}px
          }
          `}
            </style>
          )}
        </Helmet>
        <MenuLink location={location} linkData={linkData} />
        <div className={props.class}>{children}</div>
      </SEOContext.Provider>
    </>
  )
}

export default Layout

Layout.defaultProps = {
  title: "Smart Move",
}
