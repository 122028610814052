import React from "react"
import { motion } from "framer-motion"
import DynamicLink from "../DynamicLink"

const Component = (props) => {
  const { top, left, to, title, target } = props
  return (
    <div style={{ top: top + "%", left: left + "%" }}>
      <DynamicLink to={to} tabIndex="-1" target={target}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.6 }}
          transition={{ duration: 0.5 }}
        >
          <h3>{title}</h3>
        </motion.div>
      </DynamicLink>
    </div>
  )
}

export default Component
