import React, { useState } from "react"
import { Navbar } from "react-bootstrap"
import { HamburgerSpin } from "react-animated-burgers"
import DynamicLink from "../DynamicLink"
import Logo from "../Logo"
import useExtraMenuQuery from "../../hooks/useExtraMenuQuery"
import useExtraMenuIntlQuery from "../../hooks/useExtraMenuIntlQuery"

const Component = (props) => {
  const { linkData, location } = props
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)
  const extraMenu = useExtraMenuQuery()
  const extraMenuIntl = useExtraMenuIntlQuery()

  let currExtraMenu = false

  if (location.pathname.includes("international")) {
    currExtraMenu = extraMenuIntl
  } else {
    currExtraMenu = extraMenu
  }

  return (
    <Navbar
      expanded={showMenu}
      bsPrefix={`navbar navbar-front fixed-top ${showMenu ? "active" : ""}`}
    >
      <div className="container-fluid pr-0">
        <DynamicLink
          ariaLabel="Smart Move"
          tabIndex="-1"
          className="navbar-brand"
          to={"/"}
        >
          <Logo style={{ height: "1rem" }}></Logo>
        </DynamicLink>
        <HamburgerSpin
          className="navbar-toggler ml-auto navbar-hamburger"
          aria-controls="basic-navbar-nav"
          isActive={showMenu}
          toggleButton={toggleMenu}
          barColor="#fff"
          buttonWidth={30}
          tabIndex="0"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="container-fluid safe-bottom">
            <div className="row">
              {linkData && (
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-md-6" title="Start">
                      <DynamicLink to="/" target="_self">
                        <h3>Start</h3>
                      </DynamicLink>
                    </div>
                    {linkData
                      .filter((item) => item.menuLevel)
                      .map((item, i) => (
                        <div
                          className="col-md-6"
                          key={item.menuLevel + "v" + i}
                          title={item.link.title}
                        >
                          <DynamicLink
                            to={item.link.url}
                            target={item.link?.target}
                          >
                            <h3>{item.link.title}</h3>
                          </DynamicLink>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div className="col-12 col-md-4 mt-2 mt-md-0 align-self-end">
                {currExtraMenu && (
                  <div className="row">
                    {currExtraMenu.map((menuItem) => (
                      <div className="col-12" key={menuItem?.label?.toString()}>
                        <DynamicLink
                          target={menuItem?.target}
                          to={menuItem?.url}
                        >
                          <h3>{menuItem?.label}</h3>
                        </DynamicLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Component
